<template>
  <a
      v-if="item.url?.includes('https')"
      :href="item.url"
      class="menu-link mega-menu-link font-semibold text-black/40 hover:text-primary"
      :class="item.childs && item.childs.length > 0 ? 'hasChild' : ''"
  >{{ item?.name }}</a
  >
  <a
      v-else
      :href="item.url"
      class="menu-link mega-menu-link font-semibold text-black/40 hover:text-primary flex gap-1 w-full"
      :class="item.childs && item.childs.length > 0 ? 'hasChild  px-3' : 'px-3'"
  >
    <span> {{ item?.name }}</span>
    <span
        :class="item.childs && item.childs.length > 0 ? 'hidden lg:block' : 'hidden'"
        class="flex-none i-ic:twotone-keyboard-arrow-right mt-1px text-lg"
    ></span>
  </a>
  <ul v-if="item.childs && item.childs.length > 0" class="menu menu-list z-10 pl-6">
    <li v-for="(childItem, childIndex) in item.childs" :key="childIndex" class="flex gap-1">
      <MenuItemMenu :item="childItem"/>
      <span
          :class="childItem?.childs && childItem?.childs.length > 0 ? 'hidden lg:block' : 'hidden'"
          class="i-ic:twotone-keyboard-arrow-right mt-0.5 xl:mt-1 flex-none text-15px"
      ></span>
    </li>
  </ul>
</template>

<script setup lang="ts">
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})
const linkItem = computed(() => {
  if (props.item.url?.includes('http')) return 'a'
  return resolveComponent('NuxtLink')
})
</script>

<style></style>
